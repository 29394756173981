






















































.searchContainer {
  margin: 0 auto;
  padding: 24px 10px 10px;
  color:#333;
}
.wechatSearchInput {
  height: 40px;
  border: solid 1px #d5d5d5;
  background-color: #ffffff;
  margin: 12px 0;
  font-size:1.2rem;
  padding:10px;
  width:100%;
}
.search{
  width:100%;
  height:40px;
  font-size:1.4rem;
  background-color:#ed4218;
  &:active, &:focus, &:hover {font-size:1.4rem;}
}
.link {
  text-align: center;
  font-size:1.2rem;
  display:block;
  color: #333;
  margin-top:24px;
}
.search:hover, .search:visited {color: #ccc}
.link:active, .link:visited, .link:hover {color: #999}
