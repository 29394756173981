html, body{
  font-size: 62.5%;
  background-color: #FAFAFA;
  font-family: 'pingfang'
}
$red: #ec2f00;
$grey: #333;
.en {
  font-family:'teko'; 
}
.white{color:white;}

h1, h2, h3, h4, h5, a, span, p{color:#333}
p {font-size:1.4rem; line-height:2.8rem;}
a:hover {text-decoreation: underline; color:$grey}
span {font-size:1.2rem}

.red {color: #ec2f00}
.grey {color: #333}
.light-grey {color: #999}

.price {font-family:'teko'}

.font12 {
  font-size:1.2rem
}
.font14{
  font-size:1.4rem
}
.font16 {
  font-size:1.6rem
}
.font18{
  font-size:1.8rem
}
.font24 {
  font-size:2.4rem
}
.font32 {
  font-size:3.2rem
}

.pt10{
  padding-top:10px;
}
.pt20{
  padding-top:20px;
}
.pt30{
  padding-top:30px;
}
.pt40{
  padding-top:40px;
}
.pt50{
  padding-top:50px;
}
.pt60{
  padding-top:60px;
}
.pb40 {
  padding-bottom:40px;
}

.mt10 {margin-top:10px;}
.mt20{margin-top:20px;}
.mt30{margin-top:30px;}
.mt40{margin-top:40px;}
.mt50{margin-top:50px;}
.mt60{margin-top:60px;}
.mt70{margin-top:70px;}
.mt80{margin-top:80px;}
.mt100{margin-top:100px;}
.mt120{margin-top:120px;}

.fw500{
  font-weight:500
}
.fw600{
  font-weight:600
}

.pb10{
  padding-bottom:10px;
}

.mt-3 {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
  border: solid 1px #eee;
}
.input-group .form-control {
  height:40px;
  font-size:1.2rem;
  border:0;
}
.form-control:focus {
  border: none;
  box-shadow: none;
}
.input-group-append .input-group-text {
  background-color: #fff;
  height:40px;
  border:0;
  font-size:1.2rem;
}
.pointer{
  cursor: pointer;
}

input:focus{outline: none}

/* bootstrap default btn style change*/
.btn-secondary, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:focus, .btn-secondary.focus, .btn-secondary:hover, .show > .btn-secondary.dropdown-toggle {
  color: #333;
  background-color: transparent;
  border:0;
  box-shadow: 0 0 0 0;
}

/* menu tree */
.halo-tree .tree-expand {border:0;}
.halo-tree .tree-node-el{background-color: transparent}
.halo-tree li:before, .halo-tree li.leaf:after, .halo-tree li:after {border:0}
.halo-tree li {padding-left:0}
.halo-tree .first-node .node-title {font-size:1.6rem}
.halo-tree .leaf .node-title {font-size:1.2rem}
.halo-tree .inputCheck {margin-bottom:2px;}
.halo-tree .box-checked {background-color: #ec2f00; border-color :#ec2f00; border-radius:0; color:#fff;}
.halo-tree .box-checked:hover {background-color: #ec2f00}
.halo-tree .tree-open:after, .halo-tree .tree-close:after {font-size:1.8rem; font-weight:600}

/* checkbox */
.custom-control-label::before,.custom-control-label::after {width:1.5rem; height:1.5rem; margin-top:0.2rem; margin-left:-0.8rem}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {background-color:#ec2f00}
.custom-checkbox .custom-control-label::before {border-radius: 0;}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input:not(:disabled):active ~ .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::before
{border-color:transparent; background-color:transparent; box-shadow: 0 0 0 0.2rem rgba(236,47, 0, 0.25)}

/* pagination */
.page-link{border: none; color:#333;}
.pagination-lg .page-link {font-size:1.4rem}
.page-item.active .page-link {
  background-color: #fa4c06; border:1px solid #fa4c06;
}
.page-link {
  &:hover {
    color: #000;
  }
  &:focus {
    outline: 0;
    box-shadow: none;
    border:0;
  }
}
.pagination-lg .page-item:first-child .page-link,
.pagination-lg .page-item:last-child .page-link
{border-radius:0}
/* end pagination */

/* for static page*/
.introduction-bg {
  background: url('https://cdn.mobag.cn/295c0bf838db411e1feb2d56ae2e33c5') 0 -150px no-repeat #000;
  background-size: cover;
  width: 100%;
  height: 400px;
  position:relative;
  .dialog-bg {
    background: url('https://cdn.mobag.cn/2a0dce8fbedd2de24f536f298de894c0') #fff;
    background-size:contain;
    width:60%;
    min-height:380px;
    position: absolute;
    left:30%;
    top:45%;
    padding:60px;
    p {font-size:1.4rem; padding-bottom:14px;}
    .right {text-align:right;}
  }
  .title{
    position:absolute; left:-175px; top:25px; display:flex; flex-direction:column; align-items: flex-end;
    .bar {height:8px; width:33px; background-color:$red}
    span {display: block}
    .en{ font-size: 6rem; font-family:'teko'; font-weight:500; color:#fff;}
    .ch{ font-size: 2.4rem; font-weight:600; color:#fff}
  }
}
.placeholder{background-color: #000; height:35%; width:100%;}
@media (max-width:1200px) {
  .introduction-bg {
    .dialog-bg {
      top:25%;
    }
  }
}
@media (max-width:900px) {
  .introduction-bg {
    .dialog-bg {
      width:90%;
      left:5%;
      top:30%;
      padding:20px;
      p {font-size:1.2rem; padding-bottom:12px;}
    }
    .title {
      width:150px; position:absolute; left:0; top:-100px;
      .en{ font-size: 4rem; }
      .ch{ font-size: 1.4rem;}
    }
    .placeholder {height:40%;}
  }
  .placeholder{height:15%;}

}
@media (max-width:576px) {
  .placeholder{height:40%;}

}

/* modal background */
.v--modal-overlay .v--modal-background-click {background: rgba(0,0,0,0.5)}

/*buttons we design*/
.button-lg-red{
  width: 100%;
  background-color: #ec2f00;
  height:50px;
  display:flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  a{color:white; font-size:1.6rem; font-weight:600;}
}
.button-lg-red:hover {
  a{text-decoration: none}
}
.button-lg-red:active {
  opacity: 0.8;
}

