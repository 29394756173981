

















































































































.swiper-pagination-bullet{
  width:80px; height:4px; border:0; border-radius:0;
  background-color: rgba(255,255,255,0.6);
}
.swiper-pagination-bullet-active{
  background-color: #ec2f00;
}
.swiper-wrapper {
  height: fit-content;
}
