






































































































































































































































































.mobag-search{
  display:flex;
  justify-content:center;
  align-items:center;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid #ec2f00;
  padding: 10px 0;
  margin: 10px;
  background-color:#fff;
  h2{ font-size:2rem; font-weight:600; line-height:4rem}
  .title{flex:0 0 auto; margin-left:15px;}
}
.search-bread {font-size:2.4rem; font-weight:600; margin: 0 10px 24px;}
.width-for-search {width:84%}
.col-md-6 {padding:8px;}
.row-container {margin:10px;}
.flex {display:flex}
.filter{flex:1; margin: 0 10px;}
.result-list{flex:3}
.cat-container h2 {font-size:1.8rem; font-weight:600; border-bottom:1px solid #ececec;
 padding-bottom:12px; margin-bottom:9px; padding-top:20px;}
.mobile-filter{display:none}
@media screen and(max-width:575px) {
  .mobag-search{display: none;}
  .col-sm-6{padding:2px}
  .col {padding:0}
  .container-fluid {padding:0}
  .row{margin:2px;}
  .mobile-filter{margin:80px 12px 12px 12px;}
}
@media screen and(max-width:767px) {
  #webFilter{display:none}
  .mobile-filter{display:block;text-align:center;}
  .search-bread {font-size:1.6rem; font-weight:600; margin: 0 10px 16px;}
}
@media screen and (max-width:991px) {
  .width-for-search {width:100%;}
}
