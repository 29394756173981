

































































































.modal-container {
  padding:56px 100px 40px;
  position: relative;
}
.modal-title{
  .en {font-size:4rem; font-weight:500; font-family:'teko'}
  .ch {font-size:2.4rem;}
}
.modal-body {
  padding: 20px 0 0 0;
  a:hover{text-decoration: underline}
  input {border: 1px solid #ccc; height:44px; width: 100%; font-size:1.4rem; margin:0 0 12px;}
  .forget-password {font-size:1.2rem; color: #000; text-align:right; display:block; margin: 12px 0; cursor: pointer}
  .forget-password:hover {color: #000; }
  .split {
    display:flex; margin-top:40px;
    .line {width: 40%; border-top:1px solid #000; flex:1}
    span {display: block; color: #000; font-size:1.8rem; font-family:'teko'; width:40px; margin-top:-12px; text-align:center}
  }
}
.modal-btn {
  height:44px; text-align:center; cursor:pointer;
  a {color: white; line-height:44px; font-size:1.6rem; font-weight:600;}
}
.modal-btn:hover {
  text-decoration: underline;
}
.modal-btn:active {
  color: #ccc; opacity: 0.6
}
.btn-red {
  background-color:#ec2f00;
}
.btn-grey {
  background-color: #000
}
.mt8 {
  margin-top:8px;
}
.modal_close a{ color: black}
.modal_close {position:absolute; top:18px; right:20px; width:50px; height:50px;}
.modal_close .close{font-size:14px;}
.other {
  display:flex;
  align-items:center;
  .wechat-login {
    flex:2; display:flex; align-items: center;
    .logo {
      width:40px;
      height:40px;
      background:url('https://cdn.mobag.cn/c9d67a3e7ff2334d094c9ddee298d065') 0 0 no-repeat;
      background-size:contain;
      border-radius:50%;
      display:block
    }
    .wechatStr {margin-left:20px; align-items:left; font-size:1.4rem}
  }
  .regist{
    width:140px; flex:1; justify-content: center; height:44px; background-color:black;
    display:flex;align-items: center;color:white; font-size:1.6rem; font-weight:600;
  }
  .flex1{flex:1}
  .regist:active{ opacity: 0.7;}
}
