




























































































































































































.main-list {border:1px solid #e1e1e1; max-width: 1000px;margin: 50px auto;}
.main-list-mobile, .mobile {display:none}
.center {
  text-align: center;
  padding-top: 30px;
}
.title-size{ font-size: 30px;}
.mobag-header {
  background-color: black;
  color: white;
  padding:10px;
  .mobag-header-container{
    max-width: 1000px;
    height: 64px;
    margin: 0 auto;
    align-items: center;
    .mobag-logo{width:180px;float: left;margin-top: 10px;}
    img {
      width: 110px;
    }
  }
  .dealer-login {
    float: right;
    text-align:center;
    margin: 0 15px;
    height: 60px;
    a{
      color: white;
      font-size:14px;
      line-height:60px;
      cursor: pointer;
      .icon {margin-right:4px; width:1.2rem; height:1.2rem; margin-top:-3px;}
    }
  }
}
@media screen and (max-width:767px) {
  .page-top {
    margin:8px;
    .page-top-title, .search-btn-container{display:none}
  }
  .search-input {max-width:100%; margin-left:0}
  .dealer-route-container {padding:0px;}
  .main-list {display:none}
  .search-btn-container {display:none}
  .main-list-mobile {
    display:block;
    background-color:#fafafa;
    margin: 20px 20px;
  }
  .mobileItems:last-child {border-bottom:1px solid #eee}
  .emptyForMobile {margin:10px;}
  .web {display:none}
  .mobile{display: block}
  .title-size{ font-size: 24px;}
  .mobag-header {
    padding:10px;
    .mobag-header-container {
      .mobag-logo{width:120px;float: left;margin-top: 10px;}
      img{ width:110px;}
    }
    .dealer-login {
      float: right;
      text-align:center;
      margin: 0 5px;
      height: 60px;
      a{
        color: white;
        font-size:12px;
        line-height:60px;
        cursor: pointer;
        .icon {margin-right:4px; width:1.2rem; height:1.2rem; margin-top:-3px;}
      }
    }
  }
}
