


























































h1 { font-family: PingFangSC; font-size:2.4rem; font-weight:500; color:white; margin-top:14px}
  .flex1 {flex:1}
  .to-login {align-items:'flex-end'; margin-top:10px; margin-right:1%; cursor:pointer}
  .regist {
    align-items:center;
    a {
      font-size:1.6rem;
      color: #ec2f00;
      &:hover{ text-decoration:underline}
  }
    span {color:#ec2f00}
}
  .loginEntry{margin-left:20px;}
  .mobag-header {
    background-color: black;
    color: white;
    padding:10px;
    .mobag-header-container{
      width: 100%;
      padding: 0 7%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .mobag-menu-icon{
        width: 100px
    }
      img {
        width: 110px;
    }
      .mobag-nav {
        flex: 1 0 auto;
        display: flex;
        padding: 0 0 0 18px;
        a {
          padding: 20px 0;
          font-size: 1.6rem;
          color: white;
      }
    }
  }
}
  @media screen and (max-width: 767px) {
  .mobag-header {
    padding:8px;
    .mobag-header-container {
      padding: 0 10px;
      img{ width:110px; padding-top:5px; padding-bottom:5px; }
        .mobag-menu-icon{display:none}
      .mobag-nav {
          a{font-size:1rem}
          h1{font-size:1.6rem;}
    }
  }
}
}
@media screen and (max-width: 420px) {
  .to-login{display:none;}
}


