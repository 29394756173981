



































img{ width:100%; }
.close {
  display:none
}
.main {
    width:420px;
    max-width:80%;
    margin:0 auto 0;
    text-align:center;
}
.closeIcon {
  display:flex;
  justify-content: flex-end;
  color:white;
  margin-bottom:20px;
}
.imgShow {
  display:flex;
  align-items:center;
  justify-content:center;
  position:fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background-color: rgba(0,0,0,0.6);
  z-index:9;
}
