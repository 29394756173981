






















































































































































































































#webSearchByBike .dp-item > .dropdown-toggle::after { border:0 }
#webSearchByBike .dp-item button {text-align:left; font-size:1.4rem; margin-right:20px; overflow:hidden; text-overflow:ellipsis;}
#webSearchByBike .dp-item > .dropdown-menu {width: 100%; font-size:1.4rem; min-height:35px; }
#webSearchByBike .dp-item > .dropdown-menu a {overflow:hidden; text-overflow:ellipsis; font-size:1.2rem}
