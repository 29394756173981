
@font-face {
    font-family: 'teko';
    src: url('/fonts/TekoRegular.ttf') format("truetype");
}

@font-face {
    font-family: 'pingfang';
    src: url('/fonts/PingFangSCRegular.ttf');
}
