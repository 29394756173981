
















































































































































.modal-container {position: relative;}
.modal-title{
  border-bottom:1px solid #ececec;
  font-size:1.4rem;
  font-weight:600;
  padding: 18px 18px 14px;
}
.modal-body {
  padding: 0 20px 20px;
  overflow:scroll;
  height:90%;
}
.seg{border-bottom:1px solid #ececec; padding:12px;}
.modal_close a{ color: black}
.modal_close {position:absolute; top:18px; right:20px; width:50px; height:50px;}
.modal_close .close{font-size:14px;}
.operation{align-items:center; margin-top:20px; font-weight:600; color:#000; display:flex}
.operation a:active{opacity:0.6; font-size:1.2rem; }
.reset{flex:1; margin-left:10px;}

.btn-outline-primary,
.btn-outline-primary:focus, .btn-outline-primary.focus,
.btn-outline-primary:hover, .btn-outline-primary.hover,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus
{color: #333; border:0; box-shadow:none; background-color:transparent}
.btn-outline-primary:hover, .btn-outline-primary.hover {color: #ec2f00}
.card-text {padding-top:0.5rem; padding-left:1.1rem}
.card{border:0; background:transparent;box-shadow: none}
.cat-title {font-size:1.4rem; display:flex; width:100%; text-align:left; padding:0}
.symble {width:20px; align-self:center}
.text {flex:1;}
.card-body {padding-top:0.6rem; font-size:1.2rem; line-height:2.4rem}
.cat-container {margin-bottom:1rem}
.cat-block {margin-bottom:4 rem}
.custom-control-label {font-size:1.2rem}
.search-view {
  position: relative;
  margin: 1rem 0 .2rem;
  color: #333;
  input {
    border: 1px solid #c7c7c6;
    outline: none;
    display: block;
    font-size: 1.6rem;
    height: 4rem;
    padding: 0 3.5rem 0 1rem;
    line-height: 4rem;
    width: 100%;
  }
  .search-icon {
    position: absolute;
    right: 1rem;
    top: 1.1rem;
    cursor: pointer;
  }
}
