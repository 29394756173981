

















































.mobile {
  position:fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background-color:#FAFAFA;
  z-index:9;
}
.close {
  display:none;
}
.mt-3 {
  width:94%;
  margin: 0 auto;
}
.icon-search {
  color: #9e9e9e;
  font-size:1.8rem;
}
.icon-search:active {
  color: #666
}
