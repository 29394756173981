































































































































































































 h1 { font-family: PingFangSC; font-size:2.4rem; font-weight:500; color:white; margin-top:14px}
 .forMobile {display: none}
 .flex1 {flex:1}
 .nav_a {
    display: flex; height:64px; color:#666; font-size:1.5rem;
    align-items: center;
    &:hover{color: #fa4c06; cursor: pointer;}
    &.on {color: #fa4c06; border-left:4px solid #fa4c06}
 }
 .to-login {width:170px; align-self:'flex-end'; margin-top:10px;}
 .regist {
   align-items:center;
   a {
     font-size:1.6rem;
     color: #ec2f00;
     &:hover{ text-decoration:underline}
   }
   span {color:#ec2f00}
 }
.loginEntry{margin-left:20px;}
.mobag-header {
  background-color: black;
  color: white;
  padding:10px 10px 20px 10px;
  .mobag-header-container{
    width: 100%;
    padding: 0 7%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .mobag-menu-icon{
      width: 50px;
      font-size:24px;
      display:none;
    }
    img {
      width: 110px;
      margin-left: -10px;
      margin-top: -10px;
    }
    .mobag-search {
      flex: 1 1 auto;
      display:flex;
      margin: 0 0 0 10px;
      background-color: rgba(255,255,255,1);
      padding-left: 16px;
      align-items:center;
      input {
        height: 50px;
        width:80%;
        flex: auto;
        border: none;
        background-color: transparent;
        font-size:1.2rem;
        color: #666666;
      }
    }
    .mobag-nav {
      flex: 1 0 auto;
      display: flex;
      padding: 0 0 0 18px;
      a {
        padding: 20px;
        font-size: 1.6rem;
        color: white;
      }
    }
    .icon-search {
      display: inline-block;
      color:#666666;
      font-size:1.8rem;
      margin: 0 12px;
      cursor: pointer;
      &:active {color: #000000}
    }
    .mobag-search-mobile{display:none}
  }
  .dealer-login {
    text-align:right;
    padding: 0px 7% 0;
    a{
      color: white;
      font-size:1.2rem;
      line-height:2.4rem;
      cursor: pointer;
      .icon {margin-right:4px; width:1.2rem; height:1.2rem; margin-top:-3px;}
    }
  }
}
@media screen and (max-width: 767px) {
  .mobag-header {
    padding:8px;
    .mobag-header-container {
      padding: 0 10px;
      img{ width:110px; padding-top:10px; }
      .mobag-search {
        padding-left: 14px;
      }
      .mobag-nav {
        padding: 16px 0 0 10px;
        a{padding:10px;}
      }
    }
  }
}

@media screen and (max-width: 565px) {
  .loginEntry {margin-left:8px;}
  .mobag-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: #000;
    padding:4px;
    .dealer-login {
      padding: 0px 2% 0;
    }
    .mobag-header-container {
      height:48px;
      .mobag-logo{
        flex:1 1 auto;
        justify-content: center;
        display:flex;
        img {
          width: 70px;
          padding-top:0;
        }
      }
      .mobag-search, .mobag-nav{
        display: none;
      }
      .mobag-menu-icon, .mobag-search-mobile{
        display:block
      }
    }
  }
  .forWeb {display: none}
  .forMobile {
    display: inline-block; cursor:pointer
  }
}

