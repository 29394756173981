




























































p, span{font-size:1.3rem}
.list-item, .price, .money{display:flex; width:100%}
.list-item{border-top:1px solid #eee; padding:20px 10px;}
.price {margin-top:10px; display:flex}
.left{border: 1px solid #e1e1e1; width:80px; height:80px; display:flex;
   justify-content:center; align-items:center}
.left img{ width:100%; flex:none}
.right {margin-left:15px; margin-top: -10px; flex:1}
.l-money {font-size:1.5rem; color:#333; display:block; line-height:3rem; font-weight:600}
.s-money {color:#999; display:block; line-height:3rem; font-size:1.2rem; margin-left:12px}
.note{color:#999; font-size:0.9rem; display:block; margin-right:4px}
.number{font-size:1.3rem}
.numberText{font-size:0.9rem}
p{margin-bottom:0.6rem}
