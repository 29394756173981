
















.top-bar {
  background-color: white;
  color: #ccc;
  height:16px;
  padding:16px;
  font-size:1.6rem;
  text-align: right;
}
.icon-close{
  &:active {color: #666}
}
