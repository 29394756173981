


























































































.mobile {
  position:fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background-color:#FAFAFA;
  z-index:9;
}
.close {
  display:none
}
a{font-size: 1.4rem; display: block; line-height:48px; padding-left:16px;}
a:hover { text-decoration: none;}
a:active { color: #ec2f00}

.modal-container{margin:20px;}
.modal-title{color: #000; font-size:1.6rem; border-bottom:1px solid #eee}
.modal-body {
  padding: 20px 0 0 0;
  a:hover{text-decoration: underline}
  input {border: 1px solid #ccc; height:50px; width: 100%; font-size:1.4rem; margin:0 0 12px;}
  .forget-password {
    font-size:1.2rem;
    color: #000;
    text-align:center;
    display:block;
    margin: 12px 0 0;
    line-height:2.4rem;
    padding-left:0;
  }
  .forget-password:hover {color: #000; }
  .split {
    display:flex; margin-top:30px; margin-bottom:4px;
    .line {width: 40%; border-top:1px solid #000; flex:1}
    span {display: block; color: #000; font-size:1.8rem; font-family:'teko'; width:40px; margin-top:-12px; text-align:center}
  }
}
.modal-btn {
  height:44px; text-align:center; cursor:pointer;
  a {color: white; line-height:44px; font-size:1.6rem; font-weight:600;}
}
.modal-btn:hover {
  text-decoration: underline;
}
.modal-btn:active {
  color: #ccc; opacity: 0.6
}
.btn-red {
  background-color:#ec2f00;
  margin-top:28px;
}
.other {
  display:flex;
  align-items:center;
  .wechat-login {
    flex:2; display:flex; align-items: center;
    .logo {
      width:40px;
      height:40px;
      background:url('https://cdn.mobag.cn/015360329555b0444e9b30f3bbbc6e71') 0 0 no-repeat;
      background-size:contain;
      border-radius:50%;
      display:block
    }
    .wechatStr {margin-left:20px; align-items:left; font-size:1.4rem}
  }
  .regist{
    width:140px; flex:1; justify-content: center; height:44px; background-color:black;
    display:flex;align-items: center;color:white; font-size:1.6rem; font-weight:600;
  }
  .flex1{flex:1}
  .regist:active{ opacity: 0.7;}
}
