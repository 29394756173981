























































































































































































































































































.top-tab{
  width:562px; margin:0 auto; padding-top:80px; display:flex;
  .cell {
    flex:1;
    border-bottom:2px solid #eee;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size:1.6rem;
    line-height:3.2rem;
    cursor: pointer;
    .icon{margin-right:10px;}
  }
  .current {
    border-bottom:2px solid #000;
  }
}
.main {width:400px; margin: 0 auto;}
.steps-process {
  display:flex;
  align-items:center;
  .item{width:80px; text-align:center}
  .circle {
    background-color: white;
    border: solid 1px #000;
    width:38px;
    height:38px;
    display:block;
    margin: 0 auto 4px;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    span{color: black;font-family: 'teko'; font-size:2.6rem; margin-top:4px;}
  }
  span {margin-top:8px;}
  .current {
    background-color: black;
    border: solid 1px #fff;
    span{color: white;}
  }
  .line {flex:1; border-bottom:1px dashed #000; height:1px; margin:0 0 20px}
}
.country-code{width:78px; flex:none; margin-right:10px;}
.border1{border:1px solid #ccc;}
.borderRed{border:1px solid #red}
.border2{
  border-top:1px solid #ccc;
  border-bottom:1px solid #ccc;
  background-color: #fff;
}
.border3{
  border-top:1px solid #ccc;
  border-bottom:1px solid #ccc;
  border-right:1px solid #ccc;
  background-color: #fff;
}
.register .input-group-text {
  border-top:1px solid #ccc;
  border-bottom:1px solid #ccc;
  border-left:1px solid #ccc;
  background-color: #fff;
  width:90px;
  justify-content: center;
}
.btn-send-code {
  background-color: #f1f1f1;
  color: #333;
  margin:5px;
}
.input-group-append {
  border-top:1px solid #ccc;
  border-bottom:1px solid #ccc;
  border-right:1px solid #ccc;
  background-color:#fff
}
.input-group .form-control {height:50px;}
.tips {text-align:center; font-size:1.8rem; color: #999}

.item svg {display:none}
.item .passed svg {color: #000; display:inline-block; margin-top:10px; text-align:center;}
.item .passed span {display:none}
.tips svg {display:block; color:#1bb934; width: 80px; height:80px; margin:0 auto;}

.topGreyContainer{
  background-color:#f1f1f1; padding:10px; margin-bottom:60px;
  p{font-size:1.2rem; color:#333;line-height:2rem; margin:0}
}
@media (max-width:767px){
}

@media (max-width: 575px) {
}
