
































































































































.btn-outline-primary,
.btn-outline-primary:focus, .btn-outline-primary.focus,
.btn-outline-primary:hover, .btn-outline-primary.hover,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus
{color: #333; border:0; box-shadow:none; background-color:transparent}
.btn-outline-primary:hover, .btn-outline-primary.hover {color: #ec2f00}
.card-text {padding-bottom:0.5rem; padding-left:1.1rem}
.card{border:0; background:transparent;box-shadow: none}
.cat-title {
  font-size:1.6rem; display:flex; width:100%; text-align:left; padding:0
}
.symble {width:20px; align-self:center}
.text {flex:1;}
.card-body {padding-top:0.6rem; font-size:1.2rem; line-height:2.4rem}
.expanded .plus {display: none }
.expanded .minus {display: block}
.collapsed .plus {display:block}
.collapsed .minus {display: none}
.cat-container {margin-bottom:1rem}
.cat-block {margin-bottom:4 rem}
.custom-control-label {font-size:1.2rem}
.brand-collapse {
  position: relative;
  border-bottom: 1px solid #c7c7c6;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 70px;
    background: linear-gradient(rgba(255,255,255,0),#fafafa);
    pointer-events: none;
    opacity: 1;
    transition: opacity .2s linear;
    z-index: 1;
  }
}
.search-view {
  position: relative;
  margin: 1rem 0 .5rem;
  color: #333;
  input {
    border: 1px solid #c7c7c6;
    outline: none;
    display: block;
    font-size: 1.4rem;
    height: 4rem;
    padding: 0 3.5rem 0 1rem;
    line-height: 4rem;
    width: 100%;
  }
  .search-icon {
    position: absolute;
    right: 1rem;
    top: 1.1rem;
    cursor: pointer;
  }
}
.brand-box {
  max-height: 350px;
  overflow-y: auto;
  padding-bottom: 1rem;
  margin-top: 1rem;
}
.brand-collapse-down{
  &::after {
    opacity: 0;
  }
}
@media screen and(max-width:767px) {
  .cat-title {
    font-size:1.4rem; display:flex; width:100%; text-align:left
  }
  .custom-control-label {font-size:1.2rem}
}
