



























.title {
  left: -155px;
}
@media (max-width:900px) {
  .introduction-bg {
  .title {
    left:0;
    top: -100px;
  }
}
}
