














































































.icp_txt { font-size:1.2rem;  height:40px;  color: #555; padding-top:80px; padding-left:16px; max-width:1200px; margin:0 auto;}
.footer-container { background-color: black;height:403px;color: white; }
.footer-left {
  display:flex;
  max-width:1200px;
  margin:0 auto;
  padding-left:16px;
  padding-top:30px;
  .sub-column {
    display: block; margin-right:100px;
    a {display: block}
  }
  h2{ color: white; font-size:1.6rem; line-height:4rem}
  a{ color: white; opacity:0.6; font-size:1.4rem; line-height:3.6rem}
}
.table-list {display: none;}
@media screen and (max-width: 575px) {
  .table-list {display: block; padding: 9px 16px;}
  .footer-left {display: none;}
  .icp_txt{padding-left:24px;}
  .table-list{
    button {
      display:flex;
      background: transparent;
      border:0;
      width:100%;
      text-align:left;
      span { font-size:1.4rem; display:inline-block; color:#fff}
    }
    .title {flex:1; color: #fff; margin-bottom:0}
    a {color: #555; display: block; padding: 6px 8px 0; font-size:1.2rem}
    .collapse {padding-bottom:10px;}
    .icon{justify-self: flex-end}
    .collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
      display: none;
    }
  }
}
/* bootstrap default btn style change*/
.btn-secondary, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:focus, .btn-secondary.focus, .btn-secondary:hover,
.show > .btn-secondary.dropdown-toggle {
  background-color: transparent;
  border:0;
  box-shadow: 0 0 0 0;
  color:white;
}
