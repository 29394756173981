


























































.mobag-search{
  height:80px;
  display:flex;
  justify-content:center;
  align-items:center;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  .title{flex:0 0 auto;
    margin-left:10px;
    font-size:2rem;
    font-weight:600;
    display: flex;
    margin-bottom:0
  }
}
.mobag-more {
  text-align: center;
  padding-bottom: 30px;
  a {
    padding: 20px;
    font-size: 1.6rem;
    color: black;
  }
}
.mobag-hot{
  max-width:1200px; margin:0 auto; padding-top:20px;
  .hot {
    text-align:center; padding-top:8px;
  }
  h2 {text-align:center; font-size:2.4rem}
  img {width:79px;}
  .row{margin: 20px 0;}
}
.col-sm-4, .col-md-4, .col-lg-3{padding:6px}
@media screen and(max-width:575px) {
  .mobag-search{display: none;}
  .col-sm-4{padding:2px}
  .mobag-hot{
    .hot{padding-top:0}
    h2 {font-size:1.5rem}
    img {width:50px;}
  }
}
