





































  .title {
    left: -190px;
  }
  .btn-buy{
		width: 146px;
		height: 44px;
		background-color: #ec2f00;
		color: #fff;
		line-height:1.6rem;
		text-align:center;
		border:0;
		margin-top:40px;
	}
  @media (max-width:900px) {
    .introduction-bg {
    .title {
      left:0;
      top: -100px;
    }
  }
  }
