



































































.mobile {
  position:fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background-color:#FAFAFA;
  z-index:9;
}
.close {
  display:none
}
.linkContainer {
  background-color:#000;
  padding:6px;
  .item {
    width:134px;
    height:36px;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color:rgba(255,255,255,0.2);
    cursor: pointer;
    span{color:#fff;}
    .icon{margin-right:5px;}
  }
}
a{font-size: 1.4rem; display: block; line-height:48px; padding-left:16px;}
a:hover { text-decoration: none;}
a:active { color: #ec2f00}
