
















.top-bar {
  background-color: black;
  color:white;
  height:48px;
  padding:16px;
  font-size:1.6rem;
}
.icon-close{
  &:active {color: #666}
}
