







































button, button:hover, button:disabled, button:focus, button:active{
  min-width:68px;
  min-height:28px;
  border-radius:0;
  border:1px solid #000;
  background-color:#000;
  color:#fff;
  font-size:1.2rem;
  line-height:1.4rem;
  font-weight:600;
  outline: 0;
  box-shadow: none;
  svg {margin-bottom:3px; margin-right:6px}
}

.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
  box-shadow: none;
  border:1px solid #000;
  color: #fff;
  background-color: #000;
  outline: 0;
  opacity: 0.6;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #ccc; color: #fff; border:0
}
